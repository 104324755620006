@tailwind base;
@tailwind components;
@tailwind utilities;

#hubspotForm {
    font-size: 36px !important;
}
.calendly-overlay .calendly-popup {
    max-height: 100vh !important;
}

.object-position {
    object-position: 78%;
}
.bcg1 {
    background: #16222A;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #3A6073, #16222A);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #3A6073, #16222A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */  
}
.bcg2 {
    background: #16222A;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #3a7370, #16222A);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #3a7363, #16222A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */  
}
.bcg3 {
    background: #16222A;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #4e3a73, #16222A);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #4a3a73, #16222A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.bcg4 {
    background: #16222A;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #733a71, #16222A);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #733a6f, #16222A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.bcg5 {
    background: #16222A;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #733a4d, #16222A);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #733a4e, #16222A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.bgWhite {
    background-image: url("https://www.pngarts.com/files/2/Grey-Abstract-Lines-PNG-Image-Transparent.png");
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;  
    height: 120%;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    mix-blend-mode: screen;
    margin: 0 auto;
    z-index: -10;
    opacity: 0.7;
}
.bgWhite1 {
    background-image: url("https://img.freepik.com/premium-vector/modern-colorful-wavy-line-background-design-white-wave-curve-abstract-background-business-land_745217-55.jpg?w=2000");
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;  
    height: 120%;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    mix-blend-mode: screen;
    margin: 0 auto;
    z-index: -10;
    opacity: 0.7;
}
.bgWhiteA {
    background-image: url("https://img.freepik.com/premium-vector/modern-colorful-wavy-line-background-design-white-wave-curve-abstract-background-business-land_745217-55.jpg?w=2000");
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;  
    height: 120%;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    mix-blend-mode: screen;
    margin: 0 auto;
    z-index: -10;
    opacity: 0.3;
}

.cardProject:hover .arrow,
.cardProject:hover .imgProject {
    transform: scale(1.2);
}
.cardProject .arrow,
.cardProject:hover .imgProject {
    transition: all .3s ease-in-out; 
}